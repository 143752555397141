import React, { createContext, useState, useContext } from 'react';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(!!localStorage.getItem("authToken"));
  const [loginid, setLoginId] = useState(localStorage.getItem("loginid"));

  const login = (token, loginId) => {
    localStorage.setItem("authToken", token);
    localStorage.setItem("loginid", loginId);
    setIsAuthenticated(true);
    setLoginId(loginId);
  };

  const logout = () => {
    localStorage.removeItem("authToken");
    localStorage.removeItem("loginid");
    setIsAuthenticated(false);
    setLoginId(null);
  };

  return (
    <AuthContext.Provider value={{ isAuthenticated, loginid, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  return useContext(AuthContext);
};
