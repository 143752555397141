import React from "react";

const TimeDropdown = ({ value, name, onChange, error }) => {
  const times = Array.from({ length: 96 }, (_, i) => {
    const hours = Math.floor(i / 4);
    const minutes = (i % 4) * 15;
    return {
      label: `${hours < 10 ? `0${hours}` : hours}:${minutes < 10 ? `0${minutes}` : minutes} Uhr`
    };
  });

  return (
    <div className="inline-block relative">
      <select
        name={name}
        value={value}
        onChange={(e) => onChange(e.target.value)}
        className={`block appearance-none w-full bg-white focus:outline-none focus:shadow-outline ${
          error ? "error" : ""
        }`}>
        <option value="" disabled selected>
          auswählen
        </option>
        {times.map((time) => (
          <option key={time.value} value={time.value}>
            {time.label} 
          </option>
        ))}
      </select>
      <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2">
        <svg
          className="fill-current h-4 w-4"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20">
          <path d="M5.516 7.548a.25.25 0 00-.366 0 .25.25 0 000 .366l4.7 4.7a.25.25 0 00.366 0l4.7-4.7a.25.25 0 000-.366.25.25 0 00-.366 0L10 11.986l-4.484-4.438z" />
        </svg>
      </div>
    </div>
  );
};

export default TimeDropdown;
