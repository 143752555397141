import React, { useRef, useEffect } from "react";
import arrowDown from "../icons/arrow_down.svg";

const FormInput = ({
  label,
  errorMessage,
  onChange,
  id,
  current,
  ...inputProps
}) => {
  const textareaRef = useRef(null);
  //textarea height expand
  const handleTextareaChange = (event) => {
    onChange(event);
    adjustTextareaHeight();
  };

  const adjustTextareaHeight = () => {
    if (textareaRef.current) {
      textareaRef.current.style.height = "auto"; // Reset height to auto
      textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`; // Set height based on content
    }
  };

  useEffect(() => {
    adjustTextareaHeight();
  }, [inputProps.value]);

  return (
    <div className="inputContainer">
      <label htmlFor={id} className="flex flex-row justify-between">
        {label}{" "}
        {errorMessage && (
          <span className="text-red text-sm">{errorMessage}</span>
        )}
      </label>
      {inputProps.type === "textarea" ? (
        <textarea
          ref={textareaRef}
          {...inputProps}
          onChange={handleTextareaChange}
          className={`border-1 resize-none overflow-hidden p-4 ${
            errorMessage ? "error" : ""
          }`}
        />
      ) : inputProps.type === "select" ? (
        <div className="relative">
          <select
            id={id}
            name={inputProps.name}
            value={inputProps.value}
            onChange={onChange}
            className={`border-1 resize-none overflow-hidden p-4 ${
              errorMessage ? "error" : ""
            }`}
            required={inputProps.required}>
            {inputProps.options.map((option) => (
              <option
                key={option.value}
                value={option.value}
                disabled={option.disabled}>
                {option.label}
              </option>
            ))}
          </select>
          <div className="absolute inset-y-0 right-0 flex items-center pr-4 pointer-events-none">
            <img src={arrowDown} alt="Dropdown Icon" className="w-4 h-4" />
          </div>
        </div>
      ) : inputProps.type === "checkbox" ? (
        <div className="checkboxGroup flex flex-row gap-10">
          {inputProps.options.map((option, index) => (
            <div key={index} className="flex items-center gap-2">
              <input
                type="checkbox"
                name={inputProps.name}
                value={option}
                onChange={onChange}
                className={`border-1 checkbox ${errorMessage ? "error" : ""}`}
              />
              {option}
            </div>
          ))}
        </div>
      )  : (
        <input
          {...inputProps}
          onChange={onChange}
          className={`border-1 ${errorMessage ? "error" : ""}`}
        />
      )}
    </div>
  );
};

export default FormInput;
