import React, { useState } from "react";
import MailList from "./mailList";
import winter from "../images/winter.png";
import summer from "../images/summer.png";
import neutral from "../images/neutral.png";

function Publication({ values, errors, setValues, setErrors, handleNextStep }) {
  const [selectedOptions, setSelectedOptions] = useState([]);

  const handleOptionChange = (event) => {
    const value = event.target.value;
    if (event.target.checked) {
      // Add the selected option to the array
      setSelectedOptions([...selectedOptions, value]);
    } else {
      // Remove the deselected option from the array
      setSelectedOptions(selectedOptions.filter((option) => option !== value));
    }
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setValues({ ...values, [name]: value });
    setErrors({ ...errors, [name]: "" });
  };

  return (
    <div className="flex flex-col gap-6">
      {/* Auswahl der Publikationsmethode */}
      <div className="flex flex-col gap-3">
        <label>Erscheinungsort</label>
        <label className="flex flex-row gap-2 text-m items-center">
          <input
            type="checkbox"
            value="mail"
            checked={selectedOptions.includes("mail")}
            onChange={handleOptionChange}
            className="checkbox"
          />
          Mail
        </label>
        <label className="flex flex-row gap-2 text-m items-center">
          <input
            type="checkbox"
            value="website"
            checked={selectedOptions.includes("website")}
            onChange={handleOptionChange}
            className="checkbox"
          />
          Website
        </label>
        <label className="flex flex-row gap-2 text-m items-center">
          <input
            type="checkbox"
            value="socialMedia"
            checked={selectedOptions.includes("socialMedia")}
            onChange={handleOptionChange}
            className="checkbox"
          />
          Social Media
        </label>
      </div>
      {/* options */}
      {selectedOptions.includes("mail") && (
        <MailList
          values={values}
          errors={errors}
          onChange={handleChange}
          handleNextStep={handleNextStep}
        />
      )}


      {selectedOptions.includes("socialMedia") && (
        <div className="flex flex-col gap-2">
          <label>Bildoption für Social Media</label>
          <div className="flex flex-row gap-4">
            <div className="flex flex-col gap-2">
              <label className="flex flex-row gap-2 items-center">
                <input
                  type="radio"
                  value="winter"
                  name="socialMediaOption"
                  checked={values.socialMediaOption === "winter"}
                  onChange={handleChange}
                  className="w-4 h-4"
                />
                Winter
              </label>
              <img src={winter} alt="Winter" />
            </div>

            <div className="flex flex-col gap-2">
              <label className="flex flex-row gap-2 items-center">
                <input
                  type="radio"
                  value="summer"
                  name="socialMediaOption"
                  checked={values.socialMediaOption === "summer"}
                  onChange={handleChange}
                  className="w-4 h-4"
                />
                Sommer
              </label>
              <img src={summer} alt="Sommer" />
            </div>

            <div className="flex flex-col gap-2">
              <label className="flex flex-row items-center gap-2">
                <input
                  type="radio"
                  value="neutral"
                  name="socialMediaOption"
                  checked={values.socialMediaOption === "neutral"}
                  onChange={handleChange}
                  className="w-4 h-4"
                />
                Neutral
              </label>
              <img src={neutral} alt="Neutral" />
            </div>
          </div>
          
        </div>
      )}
    </div>
  );
}

export default Publication;
