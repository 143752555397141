import "./App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { AuthProvider } from "./context/AuthContext";
import PrivateRoute from "./components/privateRoute";
import Dashboard from "./pages/dashboard";
import Nav from "./components/nav";
import Lawine from "./pages/lawine";
import Roadblock from "./pages/roadblock";
import Weather from "./pages/weather";
import CancelChain from "./pages/cancelChain";
import CancelLawine from "./pages/cancelLawine";
import Chain from "./pages/chain";
import CancelRoadblock from "./pages/cancelRoadblock";
import Login from "./login/login";
import Currently from "./pages/currently";

function App() {
  return (
    <AuthProvider>
      <Router>
        <div className="flex flex-col gap-10">
          <Nav />
          <main className="p-4 lg:mx-52">
            <Routes>
              <Route path="/login" element={<Login />} />
              <Route path="/" element={<PrivateRoute><Dashboard /></PrivateRoute>} />
              <Route path="/lawine" element={<PrivateRoute><Lawine /></PrivateRoute>} />
              <Route path="/cancelLawine" element={<PrivateRoute><CancelLawine /></PrivateRoute>} />
              <Route path="/roadblock" element={<PrivateRoute><Roadblock /></PrivateRoute>} />
              <Route path="/cancelRoadblock" element={<PrivateRoute><CancelRoadblock /></PrivateRoute>} />
              <Route path="/chain" element={<PrivateRoute><Chain /></PrivateRoute>} />
              <Route path="/cancelChain" element={<PrivateRoute><CancelChain /></PrivateRoute>} />
              <Route path="/weather" element={<PrivateRoute><Weather /></PrivateRoute>} />
              <Route path="/currently" element={<PrivateRoute><Currently /></PrivateRoute>} />
            </Routes>
          </main>
        </div>
      </Router>
    </AuthProvider>
  );
}

export default App;
