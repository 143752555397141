import React, { useState, useRef } from "react";
import Stepper from "../components/stepper";
import { Link } from "react-router-dom";
import Stepback from "../components/stepback";
import FormInput from "../components/formInput";
import MailList from "../components/mailList";

const CancelLawine = () => {
  const [currentStep, setCurrentStep] = useState(1);
  const [errors, setErrors] = useState({});
  const textareaRef = useRef(null);

  const [values, setValues] = useState({
    currentDate: new Date().toISOString().split("T")[0],
    currentTime: new Date().toTimeString().split(" ")[0].substring(0, 5),
    subject: "Erfolgreicher Abschluss der künstlichen Lawinenauslösung",
    message:
      "Sehr geehrte Damen und Herren! \n \nWir teilen Ihnen mit, dass die Sicherungsarbeiten durch künstliche Lawinenauslösung (Lawinensprengungen) für die P1 Felbertauernstraße erfolgreich abgeschlossen wurden. \n \nWeitere Behinderungen sind nicht zu erwarten.\n \nFür ergänzende Informationen stehen wir Ihnen unter der Service Telefonnummer 04875 8806 10 rund um die Uhr zu Verfügung \n \nMit dem Ersuchen um Berücksichtigung bzw- Weitermeldung verbleiben wir mit freundlichen Grüßen.",
    emailList: "default",
  });

  const inputs = [
    {
      id: 1,
      name: "currentDate",
      type: "date",
      label: "Datum der Veröffentlichung",
      required: true,
    },
    {
      id: 2,
      name: "currentTime",
      type: "time",
      label: "Uhrzeit der Veröffentlichung",
      required: true,
    },
    {
      id: 3,
      name: "subject",
      type: "text",
      label: "Betreff",
      required: true,
    },
    {
      id: 4,
      name: "message",
      type: "textarea",
      label: "Mitteilung",
      required: true,
      ref: textareaRef,
      row: 17,
    },
  ];

  const handleNextStep = () => {
    const validationErrors = inputs.reduce((errors, input) => {
      if (input.required && !values[input.name]) {
        errors[input.name] = `${input.label} ist erforderlich`;
      }
      return errors;
    }, {});

    if (currentStep === 2 && values.emailList === "default") {
      validationErrors.emailList = "Bitte wählen Sie eine E-Mail aus";
    }
    setErrors(validationErrors);

    if (Object.keys(validationErrors).length === 0) {
      setCurrentStep((prevStep) => prevStep + 1);
    }
  };

  const handleStepBack = () => setCurrentStep((prevStep) => prevStep - 1);

  const onChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
    setErrors((prevErrors) => ({ ...prevErrors, [e.target.name]: "" }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Final submission logic
  };

  return (
    <div className="pb-10">
      <div className="flex flex-col gap-10">
        <Stepback currentStep={currentStep} onStepBack={handleStepBack} />
        <h3>Künstliche Lawinenauslösung Aufhebung</h3>
      </div>

      <form className="flex flex-col gap-3 mb-3" onSubmit={handleSubmit}>
        {currentStep === 1 && (
          <>
            {inputs.map((input) => (
              <FormInput
                key={input.id}
                {...input}
                value={values[input.name]}
                onChange={onChange}
                errorMessage={errors[input.name]}
              />
            ))}
            <div className="flex flex-col gap-3 mt-10">
              <button
                type="button"
                className="btn-primary"
                onClick={handleNextStep}>
                Nächster Schritt
              </button>
            </div>
          </>
        )}

        {currentStep === 2 && (
          <>
            <MailList
              values={values}
              errors={errors}
              onChange={onChange}
              handleNextStep={handleNextStep}
            />
            <div className="flex flex-col gap-3 mt-10">
              <button
                type="button"
                className="btn-primary"
                onClick={handleNextStep}>
                Nächster Schritt
              </button>
            </div>
          </>
        )}

        {currentStep === 3 && (
          <div className="flex flex-col gap-6 -mt-6">
            <p>Das Formular wurde übermittelt.</p>
            <Link className="btn-primary" to="/">
              Zurück zur Startseite
            </Link>
          </div>
        )}
      </form>

      {currentStep <= 2 && (
        <Stepper totalCount={2} activeCount={currentStep - 1} />
      )}
    </div>
  );
};

export default CancelLawine;
