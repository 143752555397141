const Stepper = ({ totalCount, activeCount }) => {
  const steps = [];

  for (let step = 0; step < totalCount; step++) {
    if (step <= activeCount) {
      steps.push(
        <div key={step} className="stepper-item completed">
          <div className="step-counter"></div>
        </div>
      );
    } else if (step === activeCount) {
      steps.push(
        <div key={step} className="stepper-item active">
          <div className="step-counter"></div>
        </div>
      );
    } else {
      steps.push(
        <div key={step} className="stepper-item">
          <div className="step-counter"></div>
        </div>
      );
    }
  }

  return <div className="stepper-wrapper">{steps}</div>;
};

export default Stepper;
