import React from "react";
import { ReactComponent as NavLogo } from "../images/Logo.svg";
import { Link } from "react-router-dom";
import { useAuth } from "../context/AuthContext";

function Nav() {
  const { isAuthenticated, loginid, loginname, logout } = useAuth();

  return (
    <div className="border-b flex border-secondary100 justify-between p-5">
      <Link to="/" className="flex items-center justify-center">
        <NavLogo />
      </Link>
      <div className="flex flex-row justify-between gap-3 items-center">
        {isAuthenticated ? (
          <div className="flex flex-col">
            <span className="font-bold">{loginname}</span>
            <button
              onClick={logout}
              className="uppercase tracking-wide-3px text-xs font-bold border-b-text border-b w-max transition hover:border-none"
            >
              Ausloggen
            </button>
          </div>
        ) : (
          <div className="flex flex-col">
            <Link
              to="/login"
              className="uppercase tracking-wide-3px text-xs font-bold border-b-text border-b w-max transition hover:border-none"
            >
              Anmelden
            </Link>
          </div>
        )}
        <div className="bg-secondary100 rounded-lg h-[50px] w-[50px]">
          <p className="text-xxxl text-center flex items-center justify-center w-full h-full">
            {loginid ? loginid[0].toUpperCase() : ""}
          </p>
        </div>
      </div>
    </div>
  );
}

export default Nav;
