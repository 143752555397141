import React from "react";
import arrowDown from "../icons/arrow_down.svg";

function MailList({ values, errors, onChange }) {
  return (
    <div className="flex flex-col gap-10">
      <div className="flex flex-col gap-1">
        <label className="flex flex-row justify-between">E-Mail Liste  {errors.emailList && (
          <span className="text-red text-sm">{errors.emailList}</span>
        )}</label>
        <div className="relative">
          <select
            name="emailList"
            value={values.emailList}
            onChange={onChange}
            required
          >
            <option value="default" selected disabled>
              Auswählen
            </option>
            <option value="mail1">Mail1</option>
            <option value="mail2">Mail2</option>
            <option value="mail3">Mail3</option>
          </select>

          <div className="absolute inset-y-0 right-0 flex items-center pr-4 pointer-events-none">
            <img src={arrowDown} alt="Dropdown Icon" className="w-4 h-4" />
          </div>
        </div>
       
      </div>
    </div>
  );
}

export default MailList;
