const weatherInputs = [
  {
    id: 1,
    name: "streetNord",
    type: "select",
    label: "Straßenzustand Zufahrt",
    required: true,
    options: [
      { value: "default", label: "Auswählen", disabled: true },
      {
        value: "1",
        label: "baustellenbedingte Behinderungen",
      },
      {
        value: "2",
        label: "keine Behinderungen",
      },
      {
        value: "3",
        label: "kurze Wartezeiten",
      },
      {
        value: "4",
        label: "Matschreste",
      },
      {
        value: "5",
        label: "Mittelspur Matschreste",
      },
      {
        value: "6",
        label: "kurze Wartezeiten",
      },
      {
        value: "7",
        label: "Mittelspur Schneefahrbahn",
      },
      {
        value: "8",
        label: "Mittelspur Schneematsch",
      },
      {
        value: "9",
        label: "regennasse Fahrbahn / durchgehend",
      },
      {
        value: "10",
        label: "regennasse Fahrbahn / teilweise",
      },
      {
        value: "11",
        label: "salzfeuchte Fahrbahn / teilweise",
      },
      {
        value: "12",
        label: "salznasse Fahrbahn / durchgehend",
      },
      {
        value: "13",
        label: "salznasse Fahrbahn / twilweise",
      },
      {
        value: "14",
        label: "Schneefahrbahn / durchgehend",
      },
      {
        value: "15",
        label: "Schneefahrbahn / teilweise",
      },
      {
        value: "16",
        label: "Schneematsch / durchgehend",
      },
      {
        value: "17",
        label: "Schneematsch / teilweise",
      },
      {
        value: "18",
        label: "trockene Fahrbahn / durchgehend",
      },
      {
        value: "19",
        label: "trockene Fahrbahn / teilweise",
      },
      {
        value: "20",
        label: "salzfeuchte Fahrbahn / durchgehend",
      },
    ],
  },
  {
    id: 2,
    name: "street2",
    type: "select",
    label: "Verkehrsaufkommen Nord-Süd",
    required: true,
    options: [
      { value: "default", label: "Auswählen", disabled: true },
      {
        value: "1",
        label: "lebhaft und störungsfrei",
      },
      {
        value: "2",
        label: "mäßig und störungsfrei",
      },
    ],
  },
  {
    id: 3,
    name: "temperatur",
    type: "number",
    label: "Temperatur",
    required: true,
    placeholder: "Temperatur in Celsius"
  },
  {
    id: 4,
    name: "weather",
    type: "select",
    label: "Wetterlage",
    required: true,
    options: [
      { value: "default", label: "Auswählen", disabled: true, selected: true },
      {
        value: "bedeckt",
        label: "bedeckt 8/8",
      },
      {
        value: "gewitter",
        label: "Gewitter",
      },
      {
        value: "heiter",
        label: "heiter 1/8 bis 2/8",
      },
      {
        value: "nebel",
        label: "Nebel",
      },
      {
        value: "5",
        label: "Regen / leicht",
      },
      {
        value: "6",
        label: "Regen / mäßig",
      },
      {
        value: "7",
        label: "Regen / stark",
      },
      {
        value: "8",
        label: "Schneeregen / leicht",
      },
      {
        value: "9",
        label: "Schneeregen / mäßig",
      },
      {
        value: "10",
        label: "Schneeregen / stark",
      },
      {
        value: "11",
        label: "stark bewölkt 5/8 bis 7/8e",
      },
      {
        value: "12",
        label: "Südföhn",
      },
      {
        value: "wolkenlos",
        label: "wolkenlos 0/8",
      },
      {
        value: "14",
        label: "wolkig 3/8 bis 4/8",
      },
    ],
  },
  {
    id: 5,
    name: "streetSouth",
    type: "select",
    label: "Straßenzustand",
    required: true,
    options: [
      { value: "default", label: "Auswählen", disabled: true },
      {
        value: "1",
        label: "baustellenbedingte Behinderungen",
      },
      {
        value: "2",
        label: "keine Behinderungen",
      },
      {
        value: "3",
        label: "kurze Wartezeiten",
      },
      {
        value: "4",
        label: "Matschreste",
      },
      {
        value: "5",
        label: "Mittelspur Matschreste",
      },
      {
        value: "6",
        label: "kurze Wartezeiten",
      },
      {
        value: "7",
        label: "Mittelspur Schneefahrbahn",
      },
      {
        value: "8",
        label: "Mittelspur Schneematsch",
      },
      {
        value: "9",
        label: "regennasse Fahrbahn / durchgehend",
      },
      {
        value: "10",
        label: "regennasse Fahrbahn / teilweise",
      },
      {
        value: "11",
        label: "salzfeuchte Fahrbahn / teilweise",
      },
      {
        value: "12",
        label: "salznasse Fahrbahn / durchgehend",
      },
      {
        value: "13",
        label: "salznasse Fahrbahn / twilweise",
      },
      {
        value: "14",
        label: "Schneefahrbahn / durchgehend",
      },
      {
        value: "15",
        label: "Schneefahrbahn / teilweise",
      },
      {
        value: "16",
        label: "Schneematsch / durchgehend",
      },
      {
        value: "17",
        label: "Schneematsch / teilweise",
      },
      {
        value: "18",
        label: "trockene Fahrbahn / durchgehend",
      },
      {
        value: "19",
        label: "trockene Fahrbahn / teilweise",
      },
      {
        value: "20",
        label: "salzfeuchte Fahrbahn / durchgehend",
      },
    ],
  },
  {
    id: 6,
    name: "street2South",
    type: "select",
    label: "Verkehrsaufkommen Süd-Nord",
    required: true,
    options: [
      { value: "default", label: "Auswählen", disabled: true },
      {
        value: "1",
        label: "lebhaft und störungsfrei",
      },
      {
        value: "2",
        label: "mäßig und störungsfrei",
      },
    ],
  },
  {
    id: 7,
    name: "temperaturSouth",
    type: "number",
    label: "Temperatur",
    required: true,
    placeholder: "Temperatur in Celsius"
  },
  {
    id: 8,
    name: "weatherSouth",
    type: "select",
    label: "Wetterlage",
    required: true,
    options: [
      { value: "default", label: "Auswählen", disabled: true, disabled: true },
      {
        value: "1",
        label: "bedeckt 8/8",
      },
      {
        value: "2",
        label: "Gewitter",
      },
      {
        value: "3",
        label: "heiter 1/8 bis 2/8",
      },
      {
        value: "4",
        label: "Nebel",
      },
      {
        value: "5",
        label: "Regen / leicht",
      },
      {
        value: "6",
        label: "Regen / mäßig",
      },
      {
        value: "7",
        label: "Regen / stark",
      },
      {
        value: "8",
        label: "Schneeregen / leicht",
      },
      {
        value: "9",
        label: "Schneeregen / mäßig",
      },
      {
        value: "10",
        label: "Schneeregen / stark",
      },
      {
        value: "11",
        label: "stark bewölkt 5/8 bis 7/8e",
      },
      {
        value: "12",
        label: "Südföhn",
      },
      {
        value: "13",
        label: "wolkenlos 0/8",
      },
      {
        value: "14",
        label: "wolkig 3/8 bis 4/8",
      },
    ],
  },
  {
    id: 9,
    name: "note",
    type: "textarea",
    label: "Sonstiges",
    placeholder: "Placeholder",
    required: true,
  },
];
export default weatherInputs;
