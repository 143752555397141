import React, { useState, useRef } from "react";
import Stepper from "../components/stepper";
import { Link } from "react-router-dom";
import Stepback from "../components/stepback";
import FormInput from "../components/formInput";
import MailList from "../components/mailList";

const Chain = () => {
  const [currentStep, setCurrentStep] = useState(1);
  const [errors, setErrors] = useState({});
  const textareaRef = useRef(null);
  const cartypes = ["KFZ > 3,5", "alle Fahrzeuge"];
  //for currenttime
  const now = new Date();
  const currentHour = now.getHours();
  const currentMinute = now.getMinutes();
  const nextFullHour = currentMinute === 0 ? currentHour : (currentHour + 1) % 24;
  const [values, setValues] = useState({
    currentDate: new Date().toISOString().split("T")[0],
    currentTime: `${nextFullHour.toString().padStart(2, "0")}:00`,
    subject: "Kettenpflicht",
    message:
      "Sehr geehrte Damen und Herren! \n \nWir teilen Ihnen mit, dass für die P1 Felbertauernstraße entlang der",
    area: "default",
    checkbox: cartypes,
    note: "Für ergänzende Informationen stehen wir Ihnen unter der Service Telefonnummer 04875 8806 10 rund um die Uhr zu Verfügung \n \nMit dem Ersuchen um Berücksichtigung bzw- Weitermeldung verbleiben wir mit freundlichen Grüßen.",
    emailList: "default",
  });

  const inputs = [
    {
      id: 1,
      name: "currentDate",
      type: "date",
      label: "Datum der Veröffentlichung",
      required: true,
    },
    {
      id: 2,
      name: "currentTime",
      type: "select",
      label: "Uhrzeit der Veröffentlichung",
      required: true,
      options: [
        {
          value: "default",
          label: "Uhrzeit auswählen",
          disabled: true,
        },
        // Generate options for all 24 hours
        ...Array.from({ length: 24 }, (_, i) => ({
          value: `${i}:00`,
          label: `${i.toString().padStart(2, "0")}:00`,
          selected: i === nextFullHour, 
        })),
      ],
    },
    {
      id: 3,
      name: "subject",
      type: "text",
      label: "Betreff",
      required: true,
    },
    {
      id: 4,
      name: "message",
      type: "textarea",
      label: "Mitteilung",
      required: true,
      ref: textareaRef,
    },
    {
      id: 5,
      name: "area",
      type: "select",
      label: "Bereich",
      required: true,
      options: [
        { value: "default", label: "Auswählen", disabled: true },
        {
          value: "Nordzufahrt",
          label:
            "Nordzufahrt zum Felbertauerntunnel, von Mittersill bis zum Felbertauerntunnel-Nordportal",
        },
        {
          value: "Suedzufahrt",
          label:
            "Südzufahrt zum Felbertauerntunnel, von Matrei bis zum Felbertauerntunnel-Südportal",
        },
        {
          value: "Ersatzverbindung",
          label: "Ersatzverbindung Schildalm",
        },
      ],
    },
    {
      id: 6,
      name: "cartype",
      type: "checkbox",
      label: "Fahrzeugtyp",
      required: true,
      options: cartypes,
    },
    {
      id: 7,
      name: "note",
      type: "textarea",
      label: "Hinweis",
      required: true,
    },
  ];

  const handleNextStep = () => {
    const validationErrors = inputs.reduce((errors, input) => {
      if (input.required && !values[input.name]) {
        errors[input.name] = `${input.label} ist erforderlich`;
      }
      // select fields
      if (input.type === "select" && values[input.name] === "default") {
        errors[input.name] = `Bitte auswählen`;
      }
      return errors;
    }, {});

    if (currentStep === 2 && values.emailList === "default") {
      validationErrors.emailList = "Bitte wählen Sie eine E-Mail aus";
    }
    setErrors(validationErrors);

    if (Object.keys(validationErrors).length === 0) {
      setCurrentStep((prevStep) => prevStep + 1);
    }
  };

  const handleStepBack = () => {
    setCurrentStep((prevStep) => prevStep - 1);
  };

  const onChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
    setErrors({ ...errors, [e.target.name]: "" }); // Clear error on change
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Additional final form submission logic goes here
  };

  return (
    <div className="pb-10">
      <div className="flex flex-col gap-10">
        <Stepback currentStep={currentStep} onStepBack={handleStepBack} />
        <h3>Kettenpflicht</h3>
      </div>

      <form className="flex flex-col gap-3 mt-10 mb-3" onSubmit={handleSubmit}>
        {/* Step 1 */}
        {currentStep === 1 && (
          <>
            {inputs.slice(0, 9).map((input) => (
              <FormInput
                key={input.id}
                {...input}
                value={values[input.name]}
                onChange={onChange}
                errorMessage={errors[input.name]}
              />
            ))}
            <div className="flex flex-col gap-3 mt-10">
              <button
                type="button"
                className="btn-primary"
                onClick={handleNextStep}>
                Nächster Schritt
              </button>
            </div>
          </>
        )}

        {/* Step 2 */}
        {currentStep === 2 && (
          <>
            <MailList
              values={values}
              errors={errors}
              onChange={onChange}
              handleNextStep={handleNextStep}
            />
            <div className="flex flex-col gap-3 mt-10">
              <button
                type="button"
                className="btn-primary"
                onClick={handleNextStep}>
                Nächster Schritt
              </button>
            </div>
          </>
        )}

        {/* Step 3 */}
        {currentStep === 3 && (
          <div className="flex flex-col gap-6 -mt-6">
            <p>Das Formular wurde übermittelt.</p>
            <Link className="btn-primary" to="/">
              Zurück zur Startseite
            </Link>
          </div>
        )}
      </form>

      {/* Stepper */}
      {currentStep <= 2 && (
        <Stepper totalCount={2} activeCount={currentStep - 1} />
      )}
    </div>
  );
};

export default Chain;
