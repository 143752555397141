"use client";

import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useAuth } from "../context/AuthContext";
import { useForm } from "react-hook-form";
import { sha512 } from "js-sha512";
import { Input, Button, Spinner } from "@nextui-org/react";

function Login() {
  const navigate = useNavigate();
  const { login, token } = useAuth();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  // Redirect if already authenticated
  useEffect(() => {
    if (token) {
      navigate("/");
    }
  });

  // React Hook Form setup
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      loginId: "",
      loginHash: "",
    },
  });

  // Handle form submission
  const onSubmit = async (data) => {
    try {
      setError(false);
      setLoading(true);
      // Call the login function
      const status = await login(data.loginId, sha512(data.loginHash));
      if (status === false) {
        setError(true);
      }

      setLoading(false);
    } catch (error) {
    // Handle errors
      console.error("Error:", error);
      setError(true);
      setLoading(false);
    }
  };

  return (
    <div className="flex flex-col gap-8">
      <h2 className="text-black">Login</h2>
        {/* Login Form */}
        <form className="flex flex-col " onSubmit={handleSubmit(onSubmit)}>
          {/* Username Input */}
          <div className="flex flex-col gap-4">
            <label>
            Benutzername*
            <Input
              variant="bordered"
              type="text"
              placeholder=" "
              {...register("loginId", { required: true })}
              isDisabled={loading}
            />
            </label>
            {errors.loginId && (
              <p className="mt-2 text-small font-medium text-red-500">
                Benutzername ist erforderlich
              </p>
            )}
            

          <label>
          Passwort*
            <Input
              variant="bordered"
              type="password"
              placeholder=" "
              {...register("loginHash", { required: true })}
              isDisabled={loading}
            />  
         </label>
            {errors.loginHash && (
              <p className="mt-2 text-small font-medium text-red-500">
                Passwort ist erforderlich
              </p>
            )}
       
          </div>
          {/* Submit Button */}
          <div className="flex flex-col gap-4 mt-16">
            <Button
            className="btn-primary"
            variant="solid"
            type="submit"
            isDisabled={loading}
            >
                {loading ? <Spinner color="default" size="sm" /> : "Einloggen"}
            </Button>
            <Link to="/" className="label">
            Passwort vergessen?
            </Link>
          </div>

          {/* Error Message */}
          {error && (
            <div className="mt-[12px]">
              <span className="text-sm text-red-500">
                Benutzername oder Passwort sind falsch
              </span>
            </div>
          )}
        </form>
    </div>
  );
}

export default Login;
