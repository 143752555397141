import React, { useState } from "react";
import Stepper from "../components/stepper";
import { Link } from "react-router-dom";
import Stepback from "../components/stepback";
import FormInput from "../components/formInput";
import MailList from "../components/mailList";
import weatherInputs from "../components/weatherInputs";

const Weather = () => {
  const [currentStep, setCurrentStep] = useState(1);
  const [errors, setErrors] = useState({});

  const [values, setValues] = useState({
    streetNord: "default",
    street2: "default",
    temperatur: "",
    weather: "default",
    streetSouth: "default",
    street2South: "default",
    temperaturSouth: "",
    weatherSouth: "default",
    note: "",
    emailList: "default",
  });

  const handleNextStep = () => {
    const validationErrors = weatherInputs.reduce((errors, input) => {
      if (input.required && !values[input.name]) {
        errors[input.name] = `${input.label} ist erforderlich`;
      }
      if (input.type === "select" && values[input.name] === "default") {
        errors[input.name] = `Bitte auswählen`;
      }
      return errors;
    }, {});

    if (currentStep === 2 && values.emailList === "default") {
      validationErrors.emailList = "Bitte wählen Sie eine E-Mail aus";
    }
    setErrors(validationErrors);

    if (Object.keys(validationErrors).length === 0) {
      setCurrentStep((prevStep) => prevStep + 1);
    }
  };

  const handleStepBack = () => {
    setCurrentStep((prevStep) => prevStep - 1);
  };

  const onChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
    setErrors({ ...errors, [e.target.name]: "" });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
  };

  return (
    <div className="pb-10 flex flex-col gap-10">
      <div className="flex flex-col gap-10">
        <Stepback currentStep={currentStep} onStepBack={handleStepBack} />
        <h3>Wetter & Straßenzustand</h3>
      </div>

      <form className="flex flex-col gap-3 mb-3" onSubmit={handleSubmit}>
        {/* Step 1 */}
        {currentStep === 1 && (
          <>
            <h4 className="pb-1">Nordzufahrt</h4>
            {weatherInputs.slice(0, 4).map((input) => (
              <FormInput
                key={input.id}
                {...input}
                value={values[input.name]}
                onChange={onChange}
                errorMessage={errors[input.name]}
              />
            ))}
            <h4 className="pt-10 pb-1">Südzufahrt</h4>
            {weatherInputs.slice(4, 8).map((input) => (
              <FormInput
                key={input.id}
                {...input}
                value={values[input.name]}
                onChange={onChange}
                errorMessage={errors[input.name]}
              />
            ))}
            <div className="flex flex-col gap-10">
              <div className="w-full h-0.5 bg-secondary100 mt-10"></div>
              {weatherInputs.slice(8).map((input) => (
                <FormInput
                  key={input.id}
                  {...input}
                  value={values[input.name]}
                  onChange={onChange}
                  errorMessage={errors[input.name]}
                />
              ))}
            </div>
            <div className="flex flex-col gap-3 mt-10">
              <button
                type="button"
                className="btn-primary"
                onClick={handleNextStep}>
                Nächster Schritt
              </button>
            </div>
          </>
        )}

        {/* Step 2 */}
        {currentStep === 2 && (
          <>
            <MailList
              values={values}
              errors={errors}
              onChange={onChange}
              handleNextStep={handleNextStep}
            />
            <div className="flex flex-col gap-3 mt-10">
              <button
                type="button"
                className="btn-primary"
                onClick={handleNextStep}>
                Nächster Schritt
              </button>
            </div>
          </>
        )}

        {/* Step 3 */}
        {currentStep === 3 && (
          <div className="flex flex-col gap-6 -mt-6">
            <p>Das Formular wurde übermittelt.</p>
            <Link className="btn-primary" to="/">
              Zurück zur Startseite
            </Link>
          </div>
        )}
      </form>

      {/* Stepper */}
      {currentStep <= 2 && (
        <Stepper totalCount={2} activeCount={currentStep - 1} />
      )}
    </div>
  );
};

export default Weather;
