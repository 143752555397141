import React from "react";
import { Link } from "react-router-dom";
import { ReactComponent as NavigationIcon } from "../icons/navigation.svg";

function dashboard() {
  return (
    <div className="flex flex-col gap-10 mb-10">
      <div className="flex flex-col gap-6">
        <h2>Aktuelle Meldungen</h2>
        <div className="flex flex-col gap-4">
          <Link
            to="/currently"
            className="flex flex-row  bg-secondary50 border-l-4 border-primary300 p-4 items-center justify-between ">
            <div className="flex flex-col gap-1">
              <label>13.12.2024</label>
              <Link to="/currently" className="font-bold">
                Wetter & Straßensperre
              </Link>
            </div>
            <div className="bg-white rounded-full h-10 w-10 flex items-center justify-center">
              <NavigationIcon width="18" height="18" />
            </div>
          </Link>
        </div>
      </div>

      <div className="flex flex-col gap-6">
        <h3>Neue Meldung</h3>
        <div className="flex flex-col gap-4">
          <div className="border border-grey50 items-center ">
            <Link
              to="/weather"
              className="flex flex-row justify-between p-4 items-center">
              Wetter & Straßenzustand
              <div className="bg-secondary50 rounded-full h-10 w-10 flex items-center justify-center">
                <NavigationIcon width="18" height="18" />
              </div>
            </Link>
          </div>

          <div className="border border-grey50 items-center ">
            <Link
              to="/lawine"
              className="flex flex-row justify-between items-center p-4">
              Künstliche Lawinenauslösung
              <div className="bg-secondary50 rounded-full h-10 w-10 flex items-center justify-center">
                <NavigationIcon width="18" height="18" />
              </div>
            </Link>
          </div>

          <div className="border border-grey50 items-center ">
            <Link
              to="/roadblock"
              className="flex flex-row justify-between p-4 items-center">
              Straßensperre
              <div className="bg-secondary50 rounded-full h-10 w-10 flex items-center justify-center">
                <NavigationIcon width="18" height="18" />
              </div>
            </Link>
          </div>

          <div className="border border-grey50 items-center ">
            <Link
              to="/chain"
              className="flex flex-row justify-between p-4 items-center">
              Kettenpflicht
              <div className="bg-secondary50 rounded-full h-10 w-10 flex items-center justify-center">
                <NavigationIcon width="18" height="18" />
              </div>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default dashboard;
