import React, { useState, useRef } from "react";
import { Link } from "react-router-dom";
import Stepback from "../components/stepback";

const Currently = () => {
  const [currentStep, setCurrentStep] = useState(1);
  const handleStepBack = () => {
    setCurrentStep((prevStep) => prevStep - 1);
  };
  return (
    <div className="flex flex-col gap-10 mb-10">
      <div className="flex flex-col gap-6">
      <div className="flex flex-col gap-10">
        <Stepback currentStep={currentStep} onStepBack={handleStepBack} />
        <h3>current heading</h3>
      </div>
        <Link className="btn-primary" to="/">
          Meldung Aufheben
        </Link>
      </div>
    </div>
  );
};

export default Currently;
